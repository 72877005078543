import React from 'react';

import Layout from '../../layouts';
import Container from '../../components/Container';
import PageTitle from '../../components/Subpage/title';
import withAuth from '../../components/Account/auth';

import loadComponents from '../../components/Loadable';

const AccountLayout = loadComponents('account');
const Orders = loadComponents('account-orders');

const page = { name: 'My Orders', slug: 'account/orders' };

const AccountOrdersBase = withAuth(props => (
  <Container>
    <AccountLayout logoutUser={props.logoutUser}>
      <Orders authUser={props.authUser} firebase={props.firebase} />
    </AccountLayout>
  </Container>
));

export default function AccountOrders({ location }) {
  const prevPage = location.state && location.state.prevPage;
  return (
    <Layout title={page.name} location={`/${page.slug}/`} prevPage={prevPage}>
      <PageTitle title={page.name} />
      <AccountOrdersBase />
    </Layout>
  );
}
